:host {
    display: block;
}

.dropzone {
    height: 100px;
    width: 100%;
    align-items: center;
    justify-content: center;
    display: flex;

    background-color: #fff;
    border: dashed 1px #aaa;
    border-radius: 4px;
}

.drag-over {
    background-color: #eee;
    border: dashed 1px gray;
}



input[type="file"] {
    display: none;
}

.uploaded-file {
    margin-top: 16px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: start;
    gap: 8px;
    padding: 0 8px;
    height: 60px;
    border: 1px solid #eee;
    border-radius: 4px;


    .file-icon {
        margin-top: 4px;
    }

    .file-name {
        flex: auto;
        color: black;
        overflow: hidden;
        text-overflow: ellipsis;
        text-wrap: nowrap;
        
    }

    .file-size {
        font-size: 13px;
        text-wrap: nowrap;
    }

    
}
.invalid-extension{
    color: tomato;
    font-size: 13px;
    margin-top: 8px;

}
